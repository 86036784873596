@import "../../../index.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.register-company {
    .bg-control {
        background-color: #fafafa;
        background-image: url(../../../assets/images/bg-jobnow.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
        min-height: 1000px;
    }
    .container-control {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-down(xl){
            width: 60%;
        }
        @include media-breakpoint-down(lg){
            width: 60%;
        }
        @include media-breakpoint-down(md){
            width: 100%;
        }

        .img-top-cover {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    .error-text{
        color: red;
        font-size: 12px;
        font-family: $fontRegular;
    }
    .img-crop{
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    .img-crop-complete{
        margin-bottom: 24px;
        width: 100px;
        height: 100px;
        object-fit: contain;
        border-radius: 50%;
        border: 2px solid $jobNowColor;
    }


    .form-control {
        padding: 28px;
        display: flex;
        border-radius: 0px;
        flex-direction: column;
        text-align: left;

        .text-header {
            
            font-size: 16px;
            color: $textBlack;
            font-family: $fontBold;
        }

        .text-desc{
            margin-left: 24px !important;
            font-size: 20px !important;
            color: $textBlack;
            font-family: $fontMedium;

            p{
                text-align: left;
                margin-bottom: 0px !important;
                font-size: 17px;
                color: $textBlack;
            }
        }

        .text-inner{
            margin-top: 6px !important;
            margin-bottom: 6px !important;
            font-size: 14px;
            color: $textBlack;
            font-family: $fontMedium;
            p, li{
                text-align: left;
                margin-bottom: 0px !important;
                font-size: 14px;
                color: $textBlack;
            }
        }

        .divider-gray{
            background-color: $borderForm;
            width: 100%;
            height: 3px;
            margin-top: 8px;
            margin-bottom: 8px;
        }
        
        .form-item-agreement{
            display: flex;
            margin-top: 24px;

            input{
                margin-top: 6px;
                margin-bottom: auto;
            }

            .text-agreement{
                margin-left: 16px;
                font-size: 16px;
                color: $textBlack;
                font-family: $fontRegular;
            }
        }
        .required-color{
            color: red;
            font-size: 16px;
        }
        .form-grid-control{
            margin-top: 16px;
            display: grid;
            grid-template-columns: 100%;

            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
            }
            

            .form-item-left{
                display: flex;
                flex-direction: column;
                @include media-breakpoint-down(md){
                    margin-right: 0px;
                }
             
            }

            .radio-control{
                margin-top: 8px;
            }

            .radio-text{
                padding-left: 16px;
                font-size: 15px;
                color: $textBlack;
                font-family: $fontMedium;
            }

            .form-item-right{
                display: flex;
                flex-direction: column;
                @include media-breakpoint-down(md){
                    margin-left: 0px;
                    margin-top: 16px;
                }
            }

         

            .input-form{
                margin-top: 4px;
                padding-left: 16px;
                padding-right: 16px;
                border: 1px solid $borderForm;
                border-radius: 4px;
                height: 44px;
                font-family: $fontRegular;
            }

            .select-control{
                margin-top: 4px;
                padding-left: 16px;
                padding-right: 16px;
                border: 1px solid $borderForm !important;
                border-radius: 4px;
                height: 44px;
                font-family: $fontRegular;
                background-color: white !important;
            }
           
            select{
                width: 100%;
                height: 100%;
                font-family: $fontRegular;
            }
        }
    }
    

    ::-webkit-file-upload-button{
        cursor: pointer;
        height: 41px;
        background-color: $jobNowColor !important;
        border-radius: 6px;
        color: white;
        border: none;
        font-family: $fontMedium;
    }

    .button-submit{
        margin-top: 24px;
        width: 100%;
        height: 51px;
        border-radius: 4px;
        background-color: $jobNowColor;
        color: white;
        font-family: $fontBold;
        font-size: 18px;
    }
}


.button-crop-submit{
    width: 100%;
    height: 51px;
    border-radius: 4px;
    background-color: $jobNowColor;
    color: white;
    font-family: $fontBold;
    font-size: 18px;
}