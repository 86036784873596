@import "../../index.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.home{

    text-align: center;
 

    .frame-option{
        background: url(../../assets/images/frame.png);
        background-repeat: no-repeat;
        width: 800px;
        height: 800px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        background-size: contain;


        .logo-grid-control{
            display: grid;
            grid-template-columns: 70% 30%;
            padding-right: 24px;
            padding-left: 36px;
        }

        .location-control{
            margin-left: 36px;
            margin-right: 36px;
            margin-top: 8px;
            .location-img{
                width: 20px;
                object-fit: contain;
                height: 28px;
            }

            .location-text{
                text-align: left;
                margin-top: auto;
                margin-bottom: auto;
                font-family: $fontMedium;
                font-size: 15px;
                color: black;
                margin-left: 16px;
              
            }
        }

        .company-name{
            margin-top: 18%;
            font-family: $fontBold;
            font-size: 35px;
            color: black;
            text-align: left !important;
            margin-right: auto;
        }

        .job-title{
            font-family: $fontBold;
            font-size: 27px;
            color: black;
            text-align: left !important;
            margin-right: auto;
        }

        .salary{
            display: flex;
            margin-left: 36px;
            text-align: left;
            margin-right: auto;
            font-family: $fontBold;
            font-size: 40px;
            color: $green;
            margin-right: auto;
        }

        .position{
            display: flex;
            margin-left: 36px;
            text-align: left;
            font-family: $fontBold;
            font-size: 30px;
            color: $jobNowColor;
        }

        .divider-2{
            width: 5px;
            background-color: black;
            height: 30px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 14px;
            margin-right: 14px;
        }

        .position-type{
            display: flex;
            text-align: left;
            font-family: $fontBold;
            font-size: 30px;
            color: $blue;
        }

        .bottom-control{
            margin-bottom: 45px;
            height: 350px;
            margin-top: auto !important;
            display: grid;
            grid-template-columns: 70% 30%;
            padding-left: 36px;
            padding-right: 36px;

            .header-text{
                text-align: left;
                font-family: $fontBold;
                font-size: 30px;
                margin-top: 15px;
                color: black;
            }

            .benefit-control{
                display: grid;
                grid-template-columns: 50% 50%;
            }

            .desc-text{
                text-align: left;
                font-family: $fontRegular;
                font-size: 15px;
                color: black;
            }
        }

        

        .divider{
            background-color: $jobNowColor;
            height: 8px;
            width: 200px;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .logo-control{
            display: flex;
            border: solid 2px black;
            width: 200px;
            height: 200px;
            border-radius: 100px;
        }
       

        .logo-img{
            position: relative;
            width: 180px;
            z-index: 5;
            margin-top: 15%;
            height: 180px;
            border-radius: 50%;
            object-fit: cover;
        }

        .register-now{
            font-size: 35px;
            color: $jobNowColor;
            font-family: $fontBold;
        }

        .qr-img{
            width: 80%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            object-fit: contain;
        }
    }

    .button-download{
        background-color: $jobNowColor;
        color: white;
        font-size: 26px;
        font-family: $fontBold;
        margin-top: 24px;
        width: 300px;
        height: 51px;

        &:hover{
            background-color: $jobNowColor !important;
        }

        &:active{
            background-color: $jobNowColor !important;
        }
    }
    
}