@import "../../index.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.apply-job {
    .bg-control {
        background-color: #fafafa;
        background-image: url(../../assets/images/bg-jobnow.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
        min-height: 1000px;
    }

    .choose-file{
       margin-top: 16px;
    }
    
    .container-control {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-down(xl){
            width: 60%;
        }
        @include media-breakpoint-down(lg){
            width: 60%;
        }
        @include media-breakpoint-down(md){
            width: 100%;
        }

        .img-top-cover {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
        .img-crop-complete{
            margin-bottom: 24px;
            width: 100px;
            height: 100px;
            object-fit: contain;
            border-radius: 50%;
            border: 2px solid $jobNowColor;
        }
        .prescreen-control{
            margin-top: 24px;
            .form-item{
                display: flex;
                flex-direction: column;
            }

            .input-form{
                background-color: white !important;
                margin-top: 4px;
                padding-left: 16px;
                padding-right: 16px;
                border: 1px solid $borderForm;
                border-radius: 4px;
                height: 44px;
                font-family: $fontRegular;
            }

            .input-form-text-area{
                background-color: white !important;
                margin-top: 4px;
                padding-top: 16px;
                padding-bottom: 16px;
                padding-left: 16px;
                padding-right: 16px;
                border: 1px solid $borderForm;
                border-radius: 4px;
                min-height: 44px;
                font-family: $fontRegular;
            }
        }

        a{
            color: #0b85cc !important;
        }

        .error-text{
            color: red;
            font-size: 12px;
            font-family: $fontRegular;
        }
        .button-submit{
            margin-top: 40px;
            width: 100%;
            height: 51px;
            background-color: $jobNowColor;
            color: white;
            border: none;
            font-size: 24px;
            font-family: $fontBold;
        }

        .grid-file-control{
            display: grid;
            grid-template-columns: 70% 30%;

            .delete-text{
                cursor: pointer;
                color: $jobNowColor;
                font-size: 16px;
                margin-left: auto;
                text-decoration: underline;
                font-family: $fontRegular;
            }
        }

        .required-color{
            color: red;
            font-size: 16px;
            font-family: $fontRegular;
        }
        .text-link-file{
            color: rgb(10, 136, 253);
            font-size: 16px;  
            font-family: $fontRegular;
        }
        .form-control {
            padding: 28px;
            display: flex;
            border-radius: 0px;
            flex-direction: column;
            text-align: left;

            .text-header {
                margin-top: 10px !important;
                font-size: 16px;
                color: $textBlack;
                font-family: $fontBold;
            }
            .text-desc-grey {
                
                font-size: 14px;
                color: #777777;
                font-family: $fontMedium;
            }
            .text-desc{
                margin-left: 24px !important;
                font-size: 20px !important;
                color: $textBlack;
                font-family: $fontMedium;

                p{
                    text-align: left;
                    margin-bottom: 0px !important;
                    font-size: 17px;
                    color: $textBlack;
                }
            }

           
            ul{
                margin-left: 15px !important;
            }
            .text-inner{
                margin-top: 6px !important;
                margin-bottom: 6px !important;
                
                font-size: 14px !important;
                color: $textBlack;
                font-family: $fontMedium;

               
                p, li{
                    margin-left: 15px;
                    text-align: left;
                    margin-bottom: 0px !important;
                    font-size: 14px;
                    color: $textBlack;
                }
            }

            .divider-gray{
                background-color: $borderForm;
                width: 100%;
                height: 3px;
                margin-top: 8px;
                margin-bottom: 8px;
            }
            
            .form-item-agreement{
                display: flex;
                margin-top: 24px;

                input{
                    margin-top: 6px;
                    margin-bottom: auto;
                }

                .text-agreement{
                    margin-left: 16px;
                    font-size: 16px;
                    color: $textBlack;
                    font-family: $fontRegular;
                }
            }
            .full-grid{
                grid-template-columns: 100% !important;
            }
            .form-grid-control{
                margin-top: 16px;
                display: grid;
                grid-template-columns: 50% 50%;

                @include media-breakpoint-down(md){
                    grid-template-columns: 100%;
                }

               
                

                .form-item-left{
                    margin-right: 6px;
                    display: flex;
                    flex-direction: column;
                    @include media-breakpoint-down(md){
                        margin-right: 0px;
                    }
                 
                }

                .form-item-right{
                    display: flex;
                    margin-left: 6px;
                    flex-direction: column;
                    @include media-breakpoint-down(md){
                        margin-left: 0px;
                        margin-top: 16px;
                    }
                }
                .teatarea-custom{
                    margin-top: 4px;
                    padding-top: 16px;
                    padding-left: 16px;
                    padding-right: 16px;
                    border: 1px solid $borderForm !important;
                    border-radius: 4px;
                    height: auto;
                    font-family: $fontRegular;
                }
             

                .input-form{
                    margin-top: 4px;
                    padding-left: 16px;
                    padding-right: 16px;
                    border: 1px solid $borderForm;
                    border-radius: 4px;
                    height: 44px;
                    font-family: $fontRegular;
                }

                .select-control{
                    margin-top: 4px;
                    padding-left: 16px;
                    padding-right: 16px;
                    border: 1px solid $borderForm !important;
                    border-radius: 4px;
                    height: 44px;
                    font-family: $fontRegular;
                    background-color: white !important;
                }
    
                select{
                    background-color: white !important;
                    width: 100%;
                    color: $textBlack !important;
                    height: 100%;
                    font-family: $fontRegular;
                }
            }
        }
        ::-webkit-file-upload-button{
            cursor: pointer;
            height: 41px;
            background-color: $jobNowColor !important;
            border-radius: 6px;
            color: white;
            border: none;
            font-family: $fontMedium;
        }
        input[type="file"]{
            color: transparent;
        }
    }
   
}
