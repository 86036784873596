@import "../../index.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.job-list {
    .bg-control {
        background-image: url(../../assets/images/bg-jobnow.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        min-height: 1000px;
    }

    .header-text{
        font-size: 36px;
        color: $textBlack;
        font-family: $fontBold;
        margin-top: 40px !important;
    }

    .grid-table{
        margin-top: 45px;
        display: grid;
        grid-template-columns: 33% 33% 33%;


        .item-control{
            height: 51px;
            border: 1px solid $textBlack;
        }

        .text-desc{
            font-size: 18px;
            font-family: $fontMedium;
        }

        .button-view{
            background-color: $jobNowColor;
            color: white;
            font-size: 20px;
            font-family: $fontBold;
            border-radius: 8px;
            width: 150px;
            height: 41px;
        }
    }


}