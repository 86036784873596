@import "../../index.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.header{


    .header-control{
        width: 100%;
        height: 61px;
        
        border-bottom: 1px solid $jobNowColor;

        .container-control{
            width: 40%;
            height: 100%;
            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-down(xl){
                width: 60%;
            }
            @include media-breakpoint-down(lg){
                width: 60%;
            }
            @include media-breakpoint-down(md){
                width: 80%;
            }
            @include media-breakpoint-down(sm){
                width: 100%;
            }

            .grid-control{
                margin-left: 5%;
                margin-right: 2%;
                height: 100%;
                display: grid;
                grid-template-columns: 50% 50%;
                @include media-breakpoint-down(md){
                    grid-template-columns: 30% 70%;
                    margin-left: 2%;
                    margin-right: 2%;
                }

                .logo-control{
                    cursor: pointer;
                    margin-top: auto;
                    margin-bottom: auto;
                    width: 109px;
                    height: 25px;
                    object-fit: contain;
                }

                .button-login{
                    height: 41px;
                    min-width: 150px;
                    background-color: white;
                    border-radius: 8px;
                    border: 3px solid $jobNowColor;
                    margin-top: auto;
                    margin-left: auto;
                    margin-bottom: auto;
                    padding-left: 16px;
                    padding-right: 16px;
                    color: $jobNowColor;
                    font-size: 18px;
                    font-family: $fontBold;
                    @include media-breakpoint-down(lg){
                        min-width: 200px;
                        font-size: 15px;
                        padding-left: 6px;
                        padding-right: 6px;
                    }
                    @include media-breakpoint-down(md){
                        min-width: 200px;
                        font-size: 13px;
                        padding-left: 6px;
                        padding-right: 6px;
                    }
                }
            }

        }
    }




}