@import "../../index.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.complete-comp{
    border: none !important;
    box-shadow: none !important;
    background-color: $borderForm !important;
    width: 100% !important;
  
    @include media-breakpoint-down(lg){
        width: 100% !important;
    }
    @include media-breakpoint-down(md){
        width: 100% !important;
    }
    .bg-control {
        display: flex;
        width: 100%;
        background-color: #fafafa;
        background-image: url(../../assets/images/bg-jobnow.png);
        background-size: contain;
        background-position: top center;
        min-height: 1100px;
    }
    .border-control{
        background-color: white;
        border-radius: 8px;
    }
   
    .lottie-control{

        margin-top: auto;
        width: 100px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
    }

    .text-loading {
        color: white;
        font-size: 22px;
        margin-top: 25px;
        font-family: $fontBold;
        text-align: center;
        animation: blink 1s infinite;
    }
    .text-header{
        color: $textBlack;
        font-size: 22px;
        margin-top: 25px;
        font-family: $fontBold;
        text-align: center;
    }

    .text-desc{
        color: $textBlack;
        font-size: 18px;
        margin-top: 25px;
        font-family: $fontRegular;
        text-align: center;
    }

    .text-close {
        cursor: pointer;
        color: $textBlack;
        font-size: 22px;
        margin-top: 25px;
        font-family: $fontBold;
        text-align: center;
        text-decoration: underline;
    }
}