@import "../index.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.complete{
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    margin-top: 100px !important;
    width: 30% !important;
  
    @include media-breakpoint-down(lg){
        width: 80% !important;
    }
    @include media-breakpoint-down(md){
        margin-top: 100px !important;
        width: 90% !important;
    }

    .border-control{
        background-color: white;
        border-radius: 8px;
    }
   
    .lottie-control{

        width: 100px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
        margin-top: 50px;
    }

    .text-loading {
        color: white;
        font-size: 22px;
        margin-top: 25px;
        font-family: $fontBold;
        text-align: center;
        animation: blink 1s infinite;
    }
    .text-header{
        color: $textBlack;
        font-size: 22px;
        margin-top: 25px;
        font-family: $fontBold;
        text-align: center;
    }

    .text-desc{
        color: $textBlack;
        font-size: 18px;
        margin-top: 25px;
        font-family: $fontRegular;
        text-align: center;
    }

    .text-close {
        cursor: pointer;
        color: $textBlack;
        font-size: 22px;
        margin-top: 25px;
        font-family: $fontBold;
        text-align: center;
        text-decoration: underline;
    }
}