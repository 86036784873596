@import "../../index.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.company-list {
    .bg-control {
        background-image: url(../../assets/images/bg-jobnow.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        min-height: 1000px;
    }

    .header-text{
        font-size: 36px;
        color: $textBlack;
        font-family: $fontBold;
        margin-top: 40px !important;
    }

  

    .grid-table{
        margin-top: 45px;
        display: grid;
        height: auto;
        grid-template-columns: 50% 50%;

        @include media-breakpoint-down(md){
            grid-template-columns: 100%;
        }

        .box-border{
            margin-bottom: 16px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            border-radius: 8px;
            box-shadow: 2px 5px 10px #888;
            border: 0px solid $textBlack;
        }

        .logo-img{
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;
        }
        .item-control{

            display: grid;
            grid-template-columns: 50% 50%;
            padding-top: 8px;
            margin-bottom: 16px;
            margin-top: 16px;
            padding-bottom: 8px;

            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
            }
        }
        .text-header{
            font-size: 18px;
            font-family: $fontBold;

            @include media-breakpoint-down(md){
                margin-top: 36px !important;
            }
        }
        .text-desc{
            font-size: 18px;
            font-family: $fontMedium;
        }

        .button-view{
            padding-left: 16px;
            padding-right: 16px;
            background-color: $jobNowColor;
            color: white;
            font-size: 14px;
            font-family: $fontRegular;
            border-radius: 8px;
            min-width: 150px;
            height: 41px;

            @include media-breakpoint-down(md){
                font-size: 18px;
                margin-bottom: 16px !important;
            }
        }
    }


}