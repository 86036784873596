@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


$jobNowColor: #E96D5D;
$green : #1DB158;
$blue: #3281C3;
$textBlack : #333333;
$borderForm: #E5E5E5;

$fontBold: "Noto-Bold";
$fontSemiBold: "Noto-Semibold";
$fontMedium: "Noto-Medium";
$fontRegular: "Noto-Regular";


@font-face {
    font-family: $fontBold;
    src: local("Noto-Bold"), url(../public/fonts/Bold.ttf) format("truetype");
}

@font-face {
    font-family: $fontSemiBold;
    src: local("Noto-Semibold"), url(../public/fonts/SemiBold.ttf) format("truetype");
}


@font-face {
    font-family: $fontMedium;
    src: local("Noto-Medium"), url(../public/fonts/Medium.ttf) format("truetype");
}


@font-face {
    font-family: $fontRegular;
    src: local("Noto-Regular"), url(../public/fonts/Regular.ttf) format("truetype");
}

